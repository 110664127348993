<!-- System: STA
    Purpose: This compoment will display add
            new company modal. where user create 
            new company
-->
<template>
  <!-- Add Company Modal -->
  <v-dialog v-model="AddCompanyModal" width="500">
    <v-card @hidden="onHidden" id="modal-addOrg">
      <v-card-title class="headline white lighten-2 justify-center">
        Add Company
        <v-spacer></v-spacer>
        <v-btn icon @click="AddCompanyModal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <!-- Add Company Form -->
        <v-form
          ref="add_company"
          @submit.prevent="onSubmit"
          lazy-validation
          id="create_company"
        >
          <div
            style="padding-right: 30px; padding-left: 30px"
            class="col-12 small-device mb-n3"
          >
            <!-- Company Image -->
            <div class="text-center cursor-poniter file-upload" v-if="!image">
              <label class="fileContainer">
                <div>
                  <img
                    src="../../assets/images/dashboard_icons/cloud_icon.svg"
                    height="100"
                    width="100"
                  />
                </div>
                <div>
                  Upload an image upto the size of 1MB
                  <br />Dimensions 174 x 75
                </div>
                <input
                  @change="onFileChange"
                  accept="image/png, image/jpg, image/jpeg"
                  name="file_name"
                  type="file"
                  height="100"
                  width="100"
                />
              </label>
            </div>
            <div class="text-center" v-else>
              <imageCroper
                :cropHeight="74"
                :cropWidth="174"
                :imgSrc="image"
                :imgStyle="{ width: 'auto', height: 'auto' }"
                :resizeable="true"
                @croped_file="attach_crop_file($event)"
                alt="Project Image"
                v-if="!cropped_file_url"
              />
              <img
                :src="cropped_file_url"
                class="img-thumbnail text-center"
                v-if="cropped_file_url"
                height="100"
                width="100"
              />
              <br />
              <button
                :class="{ 'my-margin': cropped_file_url === '' }"
                @click="removeImage"
                class="btn my-btn-theme btn-sm text-right mt-3"
                type="button"
                v-if="cropped_file_url || !cropped_file_url"
              >
                Remove image
              </button>
            </div>
          </div>
          <!-- Company Name Text Field -->
          <v-text-field
            v-model.trim="orgForm.name"
            :rules="companyNameRules"
            label="Company Name"
            required
            name="company_name"
          ></v-text-field>
          <!-- Industry Select Box -->
          <v-select
            v-model.trim="orgForm.industry"
            :items="industries"
            :rules="[(v) => !!v || 'Item is required']"
            label="Select Industry"
            required
            name="industry"
          ></v-select>
          <!-- Team Size Text Field -->
          <v-text-field
            v-model.trim="orgForm.team_size"
            label="Team Size"
            :rules="TeamSize"
            required
            name="team_size"
          ></v-text-field>
          <!-- Website Text Field -->
          <v-text-field
            v-model="orgForm.website"
            label="Company Website"
            required
            name="website"
          ></v-text-field>
          <!-- Country Select Box -->
          <v-select
            :items="countries"
            v-model="orgForm.country"
            label="Select Country"
            required
            name="country"
          ></v-select>
          <!-- Time Zone Select Box -->
          <v-select
            :items="timeZone"
            item-text="display"
            item-value="value"
            label="Select Time Zone"
            :style="{
              color: orgForm.country === '' ? '#A2B7D9' : '#36454f',
            }"
            v-model="orgForm.timezone"
            name="timezone"
          >
          </v-select>
          <!-- Save Company Button -->
          <v-btn
            :disabled="!valid || addCompanyLoader"
            color="success"
            class="mr-4"
            @click="onSubmit"
            ref="submit"
          >
            <v-progress-circular
              indeterminate
              v-if="addCompanyLoader"
              size="20"
            >
            </v-progress-circular>
            Save Company
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { companyNameRules, TeamSize } from "@/Constants/Const";
export default {
  name: "AddOrganizationModal",
  components: {
    imageCroper: () => import("@/components/includes/helper/ImageCropper"),
  },
  data() {
    return {
      valid: true,
      companyNameRules: companyNameRules,
      TeamSize: TeamSize,
      required_fields: "",
      addCompanyLoader: false,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      orgForm: {
        name: "",
        team_size: "",
        logo: "",
        id: "",
        user_status: "admin",
        industry: "",
        website: "",
        country: "",
        timezone: "",
      },
      AddCompanyModal: false,
      name_error: false,
      industries: [
        {
          text: "Select Industry ",
          value: "",
          selected: true,
          disabled: true,
        },
        "Accounting",
        "Advertising / Marketing / PR",
        "Architecture",
        "Construction",
        "Consulting - IT / Engineering",
        "Consulting - IT / Management",
        "Consulting - IT / Healthcare",
        "Consulting - IT / VAR / System Integration",
        "Financial Services",
        "Legal",
        "Medical / Pharma / Biotech",
        "Web Design",
        "Web Development",
        "Writers",
        "Journalists",
        "BOP",
        "Call Center",
        "Others",
      ],
      countries: [
        { text: "Select Country ", value: "", selected: true, disabled: true },
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua & Deps",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia",
        "Bosnia Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Central African Rep",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo",
        "Congo {Democratic Rep}",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "East Timor",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland {Republic}",
        "Israel",
        "Italy",
        "Ivory Coast",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea North",
        "Korea South",
        "Kosovo",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Myanmar, {Burma}",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "St Kitts & Nevis",
        "St Lucia",
        "Saint Vincent & the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome & Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Togo",
        "Tonga",
        "Trinidad & Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
      timeZone: [
        {
          display: "Select Time Zone ",
          value: "",
          selected: true,
          disabled: true,
        },
        {
          display: "(GMT-12:00) International Date Line West",
          value: "Midway Island, Samoa",
        },

        { display: " (GMT-09:00) Alaska", value: "Alaska" },

        {
          display: "(GMT-08:00) Pacific Time (US & Canada)",
          value: "Pacific Time (US & Canada)",
        },

        {
          display: "(GMT-08:00) Tijuana, Baja California",
          value: "Tijuana, Baja California",
        },

        { display: "(GMT-07:00) Arizona", value: "Arizona" },

        {
          display: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
          value: "Chihuahua, La Paz, Mazatlan",
        },

        {
          display: "(GMT-07:00) Mountain Time (US & Canada)",
          value: "Mountain Time (US & Canada)",
        },

        {
          display: "(GMT-06:00) Central America",
          value: "Mountain Time (US & Canada)",
        },

        {
          display: "(GMT-07:00) Mountain Time (US & Canada)",
          value: "Central America",
        },

        {
          display: "GMT-06:00) Central Time (US & Canada)",
          value: "Central Time (US & Canada)",
        },

        {
          display: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
          value: "Guadalajara, Mexico City, Monterrey",
        },

        { display: "(GMT-06:00) Saskatchewan", value: "Saskatchewan" },

        {
          display: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
          value: "Bogota, Lima, Quito, Rio Branco",
        },

        {
          display: "(GMT-05:00) Eastern Time (US & Canada)",
          value: "Eastern Time (US & Canada)",
        },

        { display: " (GMT-05:00) Indiana (East)", value: "Indiana (East)" },

        {
          display: "(GMT-04:00) Caracas, La Paz",
          value: "Caracas, La Paz",
        },

        {
          display: "(GMT-04:00) Manaus",
          value: "Caracas, La Paz",
        },

        {
          display: "(GMT-04:00) Santiago",
          value: "Santiago",
        },

        {
          display: "(GMT-03:30) Newfoundland",
          value: "Newfoundland",
        },

        {
          display: "(GMT-03:00) Brasilia",
          value: "Brasilia",
        },

        {
          display: "(GMT-03:00) Buenos Aires, Georgetown",
          value: "Buenos Aires, Georgetown",
        },

        {
          display: "(GMT-03:00) Greenland ",
          value: "Greenland",
        },

        {
          display: "(GMT-03:00) Montevideo",
          value: "Montevideo",
        },

        {
          display: "(GMT-02:00) Mid-Atlantic ",
          value: "Mid-Atlantic",
        },

        {
          display: "(GMT-01:00) Cape Verde Is.",
          value: "Cape Verde Is.",
        },
        { display: "(GMT-01:00) Azores", value: "Azores" },

        {
          display: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
          value: "Casablanca, Monrovia, Reykjavik",
        },

        {
          display:
            "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon,London",
          value: "Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
        },

        {
          display:
            "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
          value: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        },

        {
          display:
            "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
          value: "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
        },

        {
          display: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
          value: "Brussels, Copenhagen, Madrid, Paris",
        },

        {
          display: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
          value: "Sarajevo, Skopje, Warsaw, Zagreb",
        },

        {
          display: "(GMT+01:00) West Central Africa",
          value: "West Central Africa",
        },

        { display: "(GMT+02:00) Amman", value: "Amman" },

        {
          display: "(GMT+02:00) Athens, Bucharest, Istanbul",
          value: "Athens, Bucharest, Istanbul",
        },

        { display: "(GMT+02:00) Amman", value: "Amman" },

        { display: "(GMT+02:00) Beirut", value: "Beirut" },

        { display: "(GMT+02:00) Cairo", value: "Cairo" },

        {
          display: "(GMT+02:00) Harare, Pretoria",
          value: "Harare, Pretoria",
        },

        {
          display: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
          value: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
        },

        {
          display: "(GMT+02:00) Jerusalem",
          value: "Jerusalem",
        },

        { display: "(GMT+02:00) Minsk", value: "Minsk" },

        { display: "(GMT+02:00) Windhoek", value: "Windhoek" },

        {
          display: "(GMT+03:00) Kuwait, Riyadh, Baghdad",
          value: "Kuwait, Riyadh, Baghdad",
        },

        {
          display: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
          value: "Moscow, St. Petersburg, Volgograd<",
        },

        { display: "(GMT+03:00) Nairobi", value: "Nairobi" },

        { display: "(GMT+03:00) Tbilisi", value: "Minsk" },

        { display: "(GMT+03:30) Tehran", value: "Tbilisi" },

        {
          display: "(GMT+04:00) Abu Dhabi, Muscat",
          value: "Abu Dhabi, Muscat",
        },

        { display: "(GMT+04:00) Baku", value: "Baku" },
        { display: "(GMT+04:00) Yerevan", value: "Yerevan" },

        { display: "(GMT+04:30) Kabul", value: "Kabul" },

        {
          display: "(GMT+05:00) Yekaterinburg",
          value: "Yekaterinburg",
        },

        {
          display: "(GMT+05:00) Islamabad, Karachi, Tashkent",
          value: "Islamabad, Karachi, Tashkent",
        },

        {
          display: "(GMT+05:30) Sri Jayawardenapura",
          value: "Sri Jayawardenapura",
        },

        {
          display: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
          value: "Chennai, Kolkata, Mumbai, New Delhi",
        },

        {
          display: "(GMT+05:45) Kathmandu",
          value: "Kathmandu",
        },

        {
          display: "(GMT+06:00) Almaty, Novosibirsk",
          value: "Almaty, Novosibirsk<",
        },

        {
          display: "(GMT+06:00) Astana, Dhaka",
          value: "Astana, Dhaka",
        },

        {
          display: "(GMT+06:30) Yangon (Rangoon)",
          value: "Yangon (Rangoon)",
        },

        {
          display: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
          value: "Bangkok, Hanoi, Jakarta",
        },

        {
          display: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqia",
          value: "Beijing, Chongqing, Hong Kong, Urumqi",
        },

        {
          display: "(GMT+07:00) Krasnoyarsk",
          value: "Krasnoyarsk",
        },

        {
          display: "(GMT+06:00) Astana, Dhaka",
          value: "Astana, Dhaka",
        },

        {
          display: "(GMT+08:00) Kuala Lumpur, Singapore",
          value: "Kuala Lumpur, Singapore",
        },

        { display: "(GMT+08:00) Perth", value: "Perth" },

        { display: "(GMT+08:00) Taipei", value: "Taipei" },

        {
          display: "(GMT+09:00) Osaka, Sapporo, Tokyo",
          value: "Osaka, Sapporo, Tokyo",
        },

        { display: "GMT+09:00) Seoul", value: "Seoul" },

        { display: "(GMT+09:00) Yakutsk", value: "Yakutsk" },

        { display: "(GMT+09:30) Adelaide", value: "Adelaide" },

        { display: "(GMT+09:30) Darwin", value: "Darwin" },

        { display: "(GMT+10:00) Brisbane", value: "Brisbane" },

        {
          display: "(GMT+10:00) Canberra, Melbourne, Sydney",
          value: "Canberra, Melbourne, Sydney",
        },

        { display: "(GMT+10:00) Hobart", value: "Hobart" },

        {
          display: "(GMT+10:00) Guam, Port Moresby",
          value: "Guam, Port Moresby",
        },

        { display: "(GMT+10:00) Vladivostok", value: "Vladivostok" },

        {
          display: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
          value: "Magadan, Solomon Is., New Caledonia",
        },

        {
          display: "(GMT+12:00) Auckland, Wellington",
          value: "Auckland, Wellington",
        },

        {
          display: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
          value: "Fiji, Kamchatka, Marshall Is.",
        },

        { display: '(GMT+13:00) Nuku"alofa', value: "Nuku'alofa" },
      ],

      image: "",
      url: null,
      is_picture: false,
      picturePath: "",
      is_cropped: false,
      cropped_file_url: "",
    };
  },
  watch: {
    AddCompanyModal() {
      if (this.AddCompanyModal == false) {
        this.orgForm.name = "";
        this.orgForm.team_size = "";
        this.orgForm.logo = "";
        this.orgForm.id = "";
        this.orgForm.user_status = "admin";
        this.orgForm.industry = "";
        this.orgForm.website = "";
        this.orgForm.country = "";
        this.orgForm.timezone = "";
        this.required_fields = "";
        this.$refs.add_company.reset();
      }
    },
    "orgForm.name": {
      handler: function () {
        this.name_error = false;
      },
      deep: true,
    },
  },
  mounted() {
    this.$root.$on("add_company_data", () => {
      this.AddCompanyModal = true;
    });
  },
  methods: {
    /**
     * This function is resposible for
     * submitting the create comapny
     * form data and (api call)
     */
    onSubmit() {
      if (this.orgForm.name == "" || this.orgForm.team_size == "") {
        this.required_fields = "Please fill in all the required (*) fields";
      }
      if (!/\D/.test(this.orgForm.name)) {
        this.name_error = true;
        return;
      }
      let formData = new FormData(document.getElementById("create_company"));
      formData.append("logo", this.picturePath);
      formData.append("name", formData.get("company_name"));
      this.$store.commit("custom/toggle_loader", true);
      this.addCompanyLoader = true;
      this.$store
        .dispatch("custom/createCompany", formData)
        .then((response) => {
          this.addCompanyLoader = false;
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false) {
            this.orgForm.id = response.data.company_id;
            this.$store.dispatch("custom/getAllCompaniesList");
            this.$root.$emit("snack_bar", {
              show: true,
              message: "The company has been successfully added.",
              snackbarColor: "green",
            });
            this.$store.dispatch("custom/getAllCompaniesList");
            this.AddCompanyModal = false;
          }
        })
        .catch((error) => {
          this.addCompanyLoader = false;
          this.$store.commit("custom/toggle_loader", false);
          if (error.response.data.error === true) {
            if (error.response.data.errors) {
              if (error.response.data.errors.name) {
                this.$root.$emit("snack_bar", {
                  show: true,
                  message: error.response.data.errors.name[0],
                  snackbarColor: "red",
                });
              } else if (error.response.data.errors.team_size) {
                this.$root.$emit("snack_bar", {
                  show: true,
                  message: error.response.data.errors.team_size[0],
                  snackbarColor: "red",
                });
              } else {
              }
            } else {
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.error_msg,
                snackbarColor: "red",
              });
            }
          }
        });
    },
    /**
     * These function is resposible for
     * changing , updating of comapny image
     */
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let _size = file.size;
      if (_size > 1000000) {
        return;
      }
      var reader = new FileReader();
      var vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      this.picturePath = file;
      reader.readAsDataURL(file);
    },
    removeImage: function () {
      this.image = "";
      this.picturePath = "";
      this.cropped_file_url = "";
      this.is_cropped = false;
    },
    /**
     * This function is resposible for
     * clearing the data when modal closes
     */
    onHidden() {
      this.orgForm = {
        name: "",
        team_size: "",
        logo: "",
        id: "",
        user_status: "admin",
        industry: "",
        website: "",
        country: "",
        timezone: "",
      };
      this.image = "";
    },
    attach_crop_file(file_url) {
      let file_path = this.dataURLtoFile(file_url, "project_logo");
      this.cropped_file_url = file_url;
      this.picturePath = file_path;
      this.is_cropped = true;
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
  },
  computed: {},
};
</script>

<style scoped>
.file-upload {
  border: dashed 2px #cbdaf2;
  cursor: pointer !important;
  background-color: rgba(240, 248, 255, 0.93);
  box-shadow: 0 2px 4px 0 rgba(227, 227, 227, 0.45);
  color: #a2b7d9;
  font-family: "Poppins", "sans-serif";
  letter-spacing: 0.15px;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
}
.fileContainer {
  overflow: hidden;
  position: relative;
  font-size: 11px;
  height: 92px;
  /* border: dashed 2px #cbdaf2; */
  border-radius: 3px;
  background-color: rgba(240, 248, 255, 0.93);
  box-shadow: 0 2px 4px 0 rgba(227, 227, 227, 0.45);
  color: #a2b7d9;
  font-family: "Poppins", "sans-serif";
  letter-spacing: 0.15px;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
}

.fileContainer [type="file"] {
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  cursor: pointer;
}

.fileContainer img {
  height: 32px;
  width: 32px;
}
</style>
